$fa-font-path: '~@fortawesome/fontawesome-free/webfonts';

@import '@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '@fortawesome/fontawesome-free/scss/regular.scss';
@import '@fortawesome/fontawesome-free/scss/solid.scss';

@import "tailwindcss/base";
@import "tailwindcss/components";

/*! purgecss start ignore */
@import "components/buttons";
@import "components/forms";
@import "components/tables";
@import "components/typography";
@import "components/navs";
@import "components/trix";
/*! purgecss end ignore */

@import "tailwindcss/utilities";

@import "trix/dist/trix";
